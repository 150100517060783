import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Navbar8 from '../components/navbar8'
import ContentList1 from '../components/content-list1'
import Footer4 from '../components/footer4'
import './legal.css'

const Legal = (props) => {
  return (
    <div className="legal-container">
      <Helmet>
        <title>Legal - PAYlo</title>
        <meta property="og:title" content="Legal - PAYlo" />
      </Helmet>
      <Navbar8
        link1={
          <Fragment>
            <span className="legal-text10 thq-body-small thq-link">Тарифы</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="legal-text11 thq-body-small thq-link">
              Контакты
            </span>
          </Fragment>
        }
        page1={
          <Fragment>
            <span className="legal-text12 thq-body-large">Page One</span>
          </Fragment>
        }
        page2={
          <Fragment>
            <span className="legal-text13 thq-body-large">Page Two</span>
          </Fragment>
        }
        page3={
          <Fragment>
            <span className="legal-text14 thq-body-large">Page Three</span>
          </Fragment>
        }
        page4={
          <Fragment>
            <span className="legal-text15 thq-body-large">Page Four</span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="legal-text16">Начать оплату</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="legal-text17">Помощь</span>
          </Fragment>
        }
        rootClassName="navbar8-root-class-name4"
        page1Description={
          <Fragment>
            <span className="legal-text18 thq-body-small">
              Page One Description
            </span>
          </Fragment>
        }
        page2Description={
          <Fragment>
            <span className="legal-text19 thq-body-small">
              Page Two Description
            </span>
          </Fragment>
        }
        page3Description={
          <Fragment>
            <span className="legal-text20 thq-body-small">
              Page Three Description
            </span>
          </Fragment>
        }
        page4Description={
          <Fragment>
            <span className="legal-text21 thq-body-small">
              Page Four Description
            </span>
          </Fragment>
        }
      ></Navbar8>
      <ContentList1
        content1={
          <Fragment>
            <p className="legal-text22 thq-body-small">
              <span>
                Настоящий договор (Публичная оферта) определяет дальнейшие
                взаимоотношения между исполнителем - сайт paylo.one (далее
                &quot;Мы&quot;, &quot;Наша&quot;, &quot;paylo&quot;,
                &quot;paylo.one&quot;, &quot;https://paylo.one/&quot;,
                &quot;Посредник&quot;, &quot;Оператор&quot;,
                &quot;Исполнитель&quot;) с одной стороны и физическим либо
                юридическим лицом группы &quot;Пользователь&quot; (далее
                &quot;Вы&quot;, &quot;Заказчик&quot;, &quot;Клиент&quot;) с
                другой.
              </span>
              <br></br>
              <br></br>
              <span></span>
            </p>
          </Fragment>
        }
        content2={
          <Fragment>
            <p className="legal-text27 thq-body-small">
              <span>
                1.1 Факт отправки заявки на сайте https://paylo.one и (или)
                оформления Клиентом заказа через мессенджеры, либо через email
                является подтверждением полного и безоговорочным принятия
                условий данного соглашения.
              </span>
              <br></br>
              <span>
                1.2 Соглашаясь на сделку с нами, Заказчик дает согласие на
                обработку своих персональных данных согласно нашей Политике
                Конфиденциальности
              </span>
              <br></br>
              <span>
                1.2 В случае нарушения Клиентом условий данного Соглашения,
                уличении его в мошеннических и (или) противозаконных действиях,
                Посредник оставляет за собой право на разрыв данного договора в
                одностороннем порядке и использование иных соответственных мер
                по пресечению последствий этих нарушений в случае их
                возникновения.
              </span>
              <br></br>
              <span>
                1.3 Заказчик обязуется проверить сайт/сервис/услугу, который ему
                необходимо оплатить. Убедиться, что сайт не нарушает закон РФ.
              </span>
              <br></br>
              <span>
                1.4 Добавьте новую заявку на наш сервис paylo.one на оплату
              </span>
              <br></br>
              <span>1.5 Мы проверяем возможность её исполнения.</span>
              <br></br>
              <span>
                1.6 В случае возможности её оплаты, мы уведомляем Вас об этом и
                направляем реквизиты для оплаты.
              </span>
              <br></br>
              <span>
                1.7 После оплаты на наши реквизиты, Вы должны уведомить нас об
                этом и приложить чек (скриншот).
              </span>
              <br></br>
              <span>
                1.8 Мы оплачиваем необходимый сервис/товар/услугу и присылаем
                вам отчёт.
              </span>
              <br></br>
              <span></span>
            </p>
          </Fragment>
        }
        content3={
          <Fragment>
            <p className="legal-text47 thq-body-small">
              <span>
                2.1 Заказчик обязан самостоятельно проверить сайт/сервис/услугу,
                которую он хочет оплатить.
              </span>
              <br></br>
              <span>
                2.2 Мы работаем по полной предоплате, постоплата исключена.
                Запрещено отправлять средства по нашим реквизитам без
                предварительного подтверждения возможности её исполнения.
              </span>
              <br></br>
              <span>
                2.3 Убедиться, что сервис/товар/услугу не нарушает законы
                Российской Федерации.
              </span>
              <br></br>
              <span>
                2.4 Предоставить данные для входа в аккаунт, с которого
                необходимо произвести оплату.
              </span>
              <br></br>
              <span></span>
            </p>
          </Fragment>
        }
        content4={
          <Fragment>
            <p className="legal-text57 thq-body-small">
              <span>
                3.1 После предоставления всех данных и оплаты по нашим
                реквизитам мы обязаны оплатить сервис/товар/услугу в течение 2
                (двух) дней. Сроки могут быть увеличены по согласованию
                Испольнителя и Заказчика.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </p>
          </Fragment>
        }
        content5={
          <Fragment>
            <p className="legal-text60 thq-body-small">
              <span>
                4.1 Мы можем отказаться от исполнения нашей услуги
                (оплаты/пополнения и т.п. сервиса/товара/услуги и т.п. ) в любой
                этап сделки и без объяснительных причин.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </p>
          </Fragment>
        }
        content6={
          <Fragment>
            <p className="legal-text63 thq-body-small">
              <span>
                5.1 В случае успешной оплаты мы не возвращаем средства, которые
                уже были отправлены для оплаты сервиса/товара/услуги.
              </span>
              <br></br>
              <span>
                5.2 Если мы не оплатим сервис/товар/услугу в течение 2 (двух)
                дней или в другой срок, обговоренный с Заказчиком, то мы обязаны
                вернуть Ваши средства обратно Вам на счёт. Возврат
                осуществляется только на ту же платёжную систему (карту), с
                которой Вы произвели оплату.
              </span>
              <br></br>
              <span>
                5.3 Клиент (заказчик) обязан предоставить номер банковской карты
                или кошелька для возврата средств в течение 30 дней после
                получения письменного запроса (просьба, сообщение в социальной
                сети, комментарий). Необходимо предоставить номер банковской
                карты или кошелька той компании, на которую и был совершен
                платёж. Если по истечению 30 дней клиент не предоставить ответ
                на запрос, то мы оставляем за собой право не возвращать
                средства.
              </span>
              <br></br>
              <span></span>
            </p>
          </Fragment>
        }
        heading1={
          <Fragment>
            <h2 className="legal-text71 thq-heading-2">
              Правила использования
            </h2>
          </Fragment>
        }
        heading2={
          <Fragment>
            <h3 className="legal-text72 thq-heading-3">
              1.0 Общие положения и порядок оплаты
            </h3>
          </Fragment>
        }
        heading3={
          <Fragment>
            <h3 className="legal-text73 thq-heading-3">2.0 Заказчик обязан</h3>
          </Fragment>
        }
        heading4={
          <Fragment>
            <h3 className="legal-text74 thq-heading-3">
              3.0 Сроки исполнения услуги
            </h3>
          </Fragment>
        }
        heading5={
          <Fragment>
            <h3 className="legal-text75 thq-heading-3">4.0 Отказ от услуги</h3>
          </Fragment>
        }
        heading6={
          <Fragment>
            <h3 className="legal-text76 thq-heading-3">5.0 Возврат</h3>
          </Fragment>
        }
        content61={
          <Fragment>
            <p className="legal-text77 thq-body-small">
              <span>
                6.1 Если зарубежный сервис/интернет-магазин/компания откажет в
                оплате не по нашей вине (оплата не будет произведена), то мы
                вернём вам всю сумму без учёта нашей уже взятой комиссии за
                работу. Возврат осуществляется в течение 90 дней (обычно в
                течение нескольких часов). Возврат осуществляется только на ту
                платёжную систему (карту), с которой вы произвели оплату. В
                случаем успешной оплаты средства возвращаются по правилу 5.1.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </p>
          </Fragment>
        }
        heading61={
          <Fragment>
            <h3 className="legal-text80 thq-heading-3">
              6.0 Если сервис отказал в оплате
            </h3>
          </Fragment>
        }
        content611={
          <Fragment>
            <p className="legal-text81 thq-body-small">
              <span>
                7.1 В случае успешной оплаты мы не возвращаем средства, которые
                уже были отправлены для оплаты сервиса/товара/услуги.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </p>
          </Fragment>
        }
        heading611={
          <Fragment>
            <h3 className="legal-text84 thq-heading-3">
              7.0 Если сервис/сайт заблокировал Ваш аккаунт
            </h3>
          </Fragment>
        }
        content6111={
          <Fragment>
            <p className="legal-text85 thq-body-small">
              <span>
                8.1 В случае успешной оплаты мы не возвращаем средства, которые
                уже были отправлены для оплаты сервиса/товара/услуги.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </p>
          </Fragment>
        }
        heading6111={
          <Fragment>
            <h3 className="legal-text88 thq-heading-3">
              8.0 Другие обстоятельства возврата средств
            </h3>
          </Fragment>
        }
        content61111={
          <Fragment>
            <p className="legal-text89 thq-body-small">
              <span>
                10.1. Ни одна из Сторон не несет ответственности перед другой
                Стороной за невыполнение обязательств, вызванное
                обстоятельствами, возникшими помимо воли и желания Сторон,
                которые нельзя было предвидеть или избежать, включая объявленную
                или фактическую войну, гражданские волнения, эпидемии,
                землетрясения, наводнения, пожары и другие стихийные бедствия,
                действия государственных или муниципальных органов и прочие
                непреодолимые обстоятельства.
              </span>
              <br></br>
              <span>
                10.2. Сторона, которая не исполняет своего обязательства
                вследствие действия непреодолимой силы, должна известить другую
                Сторону о препятствии и его влиянии на исполнение обязательств
                без промедления, но не позднее 30 (тридцати) дней с момента
                наступления указанных обстоятельств.
              </span>
              <br></br>
              <span></span>
            </p>
          </Fragment>
        }
        content61112={
          <Fragment>
            <p className="legal-text95 thq-body-small">
              9.1 Актуальная и полная комиссия устанавливается в телеграм-канале
              сервиса paylo. Комиссия зависит от суммы платежа и метода платежа.
            </p>
          </Fragment>
        }
        heading61111={
          <Fragment>
            <h3 className="legal-text96 thq-heading-3">
              10.0 Форс-мажорные обстоятельства
            </h3>
          </Fragment>
        }
        heading61112={
          <Fragment>
            <h3 className="legal-text97 thq-heading-3">9.0 Комиссия сервиса</h3>
          </Fragment>
        }
        content611111={
          <Fragment>
            <p className="legal-text98 thq-body-small">
              12.1 Правила сервисов и оплаты постоянно меняются, поэтому мы
              вправе изменять данные правила без дополнительного уведомления.
              Актуальные правила пользования сервисом всегда доступны по адресу
              https://paylo.one/legal/
            </p>
          </Fragment>
        }
        content611112={
          <Fragment>
            <p className="legal-text99 thq-body-small">
              11.1 Данные правила доступны в публичной оферте. Актуальное
              правила находится по адресу: https://paylo.one/legal/
            </p>
          </Fragment>
        }
        heading611111={
          <Fragment>
            <h3 className="legal-text100 thq-heading-3">
              12.0 Изменение данных правил
            </h3>
          </Fragment>
        }
        heading611112={
          <Fragment>
            <h3 className="legal-text101 thq-heading-3">
              11.0 Публичная оферта
            </h3>
          </Fragment>
        }
      ></ContentList1>
      <Footer4
        link2={
          <Fragment>
            <span className="legal-text102 thq-body-small">Мы в ТГ</span>
          </Fragment>
        }
        termsLink={
          <Fragment>
            <span className="legal-text103 thq-body-small">Оферта</span>
          </Fragment>
        }
        rootClassName="footer4-root-class-name1"
      ></Footer4>
    </div>
  )
}

export default Legal
