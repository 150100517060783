import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './footer4.css'

const Footer4 = (props) => {
  return (
    <footer
      className={`footer4-footer7 thq-section-padding ${props.rootClassName} `}
    >
      <div className="footer4-max-width thq-section-max-width">
        <div className="footer4-content">
          <div className="footer4-links">
            <a
              href="https://t.me/paylo_global"
              target="_blank"
              rel="noreferrer noopener"
            >
              {props.link2 ?? (
                <Fragment>
                  <span className="footer4-text3 thq-body-small">Мы в ТГ</span>
                </Fragment>
              )}
            </a>
          </div>
        </div>
        <div className="footer4-credits">
          <div className="thq-divider-horizontal"></div>
          <div className="footer4-row">
            <div className="footer4-container1">
              <span className="footer4-text1 thq-body-small">© 2024 PAYlo</span>
            </div>
            <div className="footer4-container2">
              <img
                alt={props.imageAlt}
                src="https://yzruscgerbeansacbvyb.supabase.co/storage/v1/object/public/site/services/VISA.png"
                className="footer4-image1"
              />
              <img
                alt={props.imageAlt2}
                src="https://yzruscgerbeansacbvyb.supabase.co/storage/v1/object/public/site/services/MC.png"
                className="footer4-image2"
              />
              <img
                alt={props.imageAlt1}
                src="https://yzruscgerbeansacbvyb.supabase.co/storage/v1/object/public/site/services/USDT.png"
                className="footer4-image3"
              />
            </div>
            <div className="footer4-footer-links">
              <Link to="/legal" className="footer4-text2">
                {props.termsLink ?? (
                  <Fragment>
                    <span className="footer4-text4 thq-body-small">Оферта</span>
                  </Fragment>
                )}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer4.defaultProps = {
  imageAlt2: 'image',
  imageAlt: 'image',
  rootClassName: '',
  link2: undefined,
  imageAlt1: 'image',
  termsLink: undefined,
}

Footer4.propTypes = {
  imageAlt2: PropTypes.string,
  imageAlt: PropTypes.string,
  rootClassName: PropTypes.string,
  link2: PropTypes.element,
  imageAlt1: PropTypes.string,
  termsLink: PropTypes.element,
}

export default Footer4
