import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Navbar8 from '../components/navbar8'
import Contact11 from '../components/contact11'
import Footer4 from '../components/footer4'
import './contact.css'

const Contact = (props) => {
  return (
    <div className="contact-container">
      <Helmet>
        <title>Contact - PAYlo</title>
        <meta property="og:title" content="Contact - PAYlo" />
      </Helmet>
      <Navbar8
        link1={
          <Fragment>
            <span className="contact-text10 thq-body-small thq-link">
              Тарифы
            </span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="contact-text11 thq-body-small thq-link">
              Контакты
            </span>
          </Fragment>
        }
        page1={
          <Fragment>
            <span className="contact-text12 thq-body-large">Page One</span>
          </Fragment>
        }
        page2={
          <Fragment>
            <span className="contact-text13 thq-body-large">Page Two</span>
          </Fragment>
        }
        page3={
          <Fragment>
            <span className="contact-text14 thq-body-large">Page Three</span>
          </Fragment>
        }
        page4={
          <Fragment>
            <span className="contact-text15 thq-body-large">Page Four</span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="contact-text16">Начать оплату</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="contact-text17">Помощь</span>
          </Fragment>
        }
        rootClassName="navbar8-root-class-name3"
        page1Description={
          <Fragment>
            <span className="contact-text18 thq-body-small">
              Page One Description
            </span>
          </Fragment>
        }
        page2Description={
          <Fragment>
            <span className="contact-text19 thq-body-small">
              Page Two Description
            </span>
          </Fragment>
        }
        page3Description={
          <Fragment>
            <span className="contact-text20 thq-body-small">
              Page Three Description
            </span>
          </Fragment>
        }
        page4Description={
          <Fragment>
            <span className="contact-text21 thq-body-small">
              Page Four Description
            </span>
          </Fragment>
        }
      ></Navbar8>
      <Contact11
        email={
          <Fragment>
            <span className="contact-text22 thq-body-small">
              sale@paylo.one
            </span>
          </Fragment>
        }
        email1={
          <Fragment>
            <span className="contact-text23 thq-body-small">
              https://t.me/paylo_global
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <h2 className="contact-text24 thq-heading-2">Контакты</h2>
          </Fragment>
        }
      ></Contact11>
      <Footer4
        link2={
          <Fragment>
            <span className="contact-text25 thq-body-small">Мы в ТГ</span>
          </Fragment>
        }
        termsLink={
          <Fragment>
            <span className="contact-text26 thq-body-small">Оферта</span>
          </Fragment>
        }
        rootClassName="footer4-root-class-name"
      ></Footer4>
    </div>
  )
}

export default Contact
