import React, { Fragment } from 'react'

import Script from 'dangerous-html/react'
import PropTypes from 'prop-types'

import './hero17.css'

const Hero17 = (props) => {
  return (
    <div className="hero17-header78">
      <div className="hero17-column thq-section-max-width thq-section-padding">
        <div className="hero17-content1">
          <h1>
            {props.heading1 ?? (
              <Fragment>
                <h1 className="hero17-text5 thq-heading-1">
                  Поможем оплатить зарубежные сервисы
                </h1>
              </Fragment>
            )}
          </h1>
          <p>
            {props.content1 ?? (
              <Fragment>
                <p className="hero17-text8 thq-body-large">
                  Комиссия от 6%. Оплата криптовалютой, СБП, P2P
                </p>
              </Fragment>
            )}
          </p>
        </div>
        <div className="hero17-actions">
          <a
            href="https://t.me/paylo_support"
            target="_blank"
            rel="noreferrer noopener"
            className="hero17-button1 thq-button-filled"
          >
            <span>
              {props.action1 ?? (
                <Fragment>
                  <span className="hero17-text6 thq-body-small">
                    Оплатить подписку
                  </span>
                </Fragment>
              )}
            </span>
          </a>
          <a
            href="https://t.me/paylo_support"
            target="_blank"
            rel="noreferrer noopener"
            className="hero17-button2 thq-button-outline"
          >
            <span>
              {props.action2 ?? (
                <Fragment>
                  <span className="hero17-text7 thq-body-small">
                    Задать вопрос
                  </span>
                </Fragment>
              )}
            </span>
          </a>
        </div>
      </div>
      <div className="hero17-container1">
        <div className="hero17-container2">
          <div className="hero17-container3">
            <Script
              html={`<div id="exchange-rate-container">
    <div id="exchange-rate">Загрузка курса...</div>
    <div id="last-updated"></div>
</div>

<style>
    #exchange-rate-container {
        font-family: 'Martian Mono', monospace;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        padding: 20px;
        text-align: center;
        max-width: 300px;
        margin: 20px auto;
    }
    #exchange-rate {
        font-size: 18px;
        font-weight: 700;
        color: #2c3e50;
    }
    #last-updated {
        font-size: 12px;
        color: #7f8c8d;
        margin-top: 10px;
    }
</style>

<script>
    function updateExchangeRate() {
        fetch('https://api.exchangerate-api.com/v4/latest/USD')
            .then(response => response.json())
            .then(data => {
                const rate = data.rates.RUB;
                const rateWithMarkup = rate * 1.05; // Добавляем 5% наценку
                const formattedRate = rateWithMarkup.toFixed(2);
                document.getElementById('exchange-rate').innerHTML = \`1 USD = \${formattedRate} RUB<br>\`;
                
                const now = new Date();
                document.getElementById('last-updated').innerHTML = \`Обновлено: \${now.toLocaleTimeString()}\`;
            })
            .catch(error => {
                console.error('Ошибка при получении курса:', error);
                document.getElementById('exchange-rate').innerHTML = 'Ошибка при получении курса';
            });
    }

    // Обновляем курс сразу при загрузке страницы
    updateExchangeRate();

    // Обновляем курс каждую минуту
    setInterval(updateExchangeRate, 60000);
</script>`}
            ></Script>
          </div>
        </div>
      </div>
      <div className="hero17-content2">
        <div className="hero17-row-container1 thq-mask-image-horizontal thq-animated-group-container-horizontal">
          <div className="thq-animated-group-horizontal">
            <img
              alt={props.image1Alt}
              src="https://yzruscgerbeansacbvyb.supabase.co/storage/v1/object/public/site/services/adobe.png"
              className="hero17-placeholder-image10 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image2Alt}
              src="https://yzruscgerbeansacbvyb.supabase.co/storage/v1/object/public/site/services/amazon.png"
              className="hero17-placeholder-image11 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image3Alt}
              src="https://yzruscgerbeansacbvyb.supabase.co/storage/v1/object/public/site/services/discord.png"
              className="hero17-placeholder-image12 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image4Alt}
              src="https://yzruscgerbeansacbvyb.supabase.co/storage/v1/object/public/site/services/figma.png"
              className="hero17-placeholder-image13 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image5Alt}
              src="https://yzruscgerbeansacbvyb.supabase.co/storage/v1/object/public/site/services/ebay.png"
              className="hero17-placeholder-image14 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image6Alt}
              src="https://yzruscgerbeansacbvyb.supabase.co/storage/v1/object/public/site/services/netflix.png"
              className="hero17-placeholder-image15 thq-img-ratio-1-1 thq-img-scale"
            />
          </div>
          <div className="thq-animated-group-horizontal">
            <img
              alt={props.image1Alt}
              src="https://yzruscgerbeansacbvyb.supabase.co/storage/v1/object/public/site/services/adobe.png"
              className="hero17-placeholder-image16 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image2Alt}
              src="https://yzruscgerbeansacbvyb.supabase.co/storage/v1/object/public/site/services/amazon.png"
              className="hero17-placeholder-image17 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image3Alt}
              src="https://yzruscgerbeansacbvyb.supabase.co/storage/v1/object/public/site/services/discord.png"
              className="hero17-placeholder-image18 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image4Alt}
              src="https://yzruscgerbeansacbvyb.supabase.co/storage/v1/object/public/site/services/figma.png"
              className="hero17-placeholder-image19 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image5Alt}
              src="https://yzruscgerbeansacbvyb.supabase.co/storage/v1/object/public/site/services/ebay.png"
              className="hero17-placeholder-image20 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt="Hero Image"
              src="https://yzruscgerbeansacbvyb.supabase.co/storage/v1/object/public/site/services/netflix.png"
              className="hero17-placeholder-image21 thq-img-ratio-1-1 thq-img-scale"
            />
          </div>
        </div>
        <div className="hero17-row-container2 thq-mask-image-horizontal thq-animated-group-container-horizontal">
          <div className="thq-animated-group-horizontal-reverse">
            <img
              alt={props.image7Alt}
              src="https://yzruscgerbeansacbvyb.supabase.co/storage/v1/object/public/site/services/nike.png"
              className="hero17-placeholder-image22 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image8Alt}
              src="https://yzruscgerbeansacbvyb.supabase.co/storage/v1/object/public/site/services/nintendo.png"
              className="hero17-placeholder-image23 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image9Alt}
              src="https://yzruscgerbeansacbvyb.supabase.co/storage/v1/object/public/site/services/playstation.png"
              className="hero17-placeholder-image24 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image10Alt}
              src="https://yzruscgerbeansacbvyb.supabase.co/storage/v1/object/public/site/services/spotify.png"
              className="hero17-placeholder-image25 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image11Alt}
              src="https://yzruscgerbeansacbvyb.supabase.co/storage/v1/object/public/site/services/twitch.png"
              className="hero17-placeholder-image26 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image12Alt}
              src="https://yzruscgerbeansacbvyb.supabase.co/storage/v1/object/public/site/services/xbox.png"
              className="hero17-placeholder-image27 thq-img-ratio-1-1 thq-img-scale"
            />
          </div>
          <div className="thq-animated-group-horizontal-reverse">
            <img
              alt={props.image7Alt}
              src="https://yzruscgerbeansacbvyb.supabase.co/storage/v1/object/public/site/services/nike.png"
              className="hero17-placeholder-image28 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image8Alt}
              src="https://yzruscgerbeansacbvyb.supabase.co/storage/v1/object/public/site/services/nintendo.png"
              className="hero17-placeholder-image29 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image9Alt}
              src="https://yzruscgerbeansacbvyb.supabase.co/storage/v1/object/public/site/services/playstation.png"
              className="hero17-placeholder-image30 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image10Alt}
              src="https://yzruscgerbeansacbvyb.supabase.co/storage/v1/object/public/site/services/spotify.png"
              className="hero17-placeholder-image31 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image11Alt}
              src="https://yzruscgerbeansacbvyb.supabase.co/storage/v1/object/public/site/services/twitch.png"
              className="hero17-placeholder-image32 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt="Hero Image"
              src="https://yzruscgerbeansacbvyb.supabase.co/storage/v1/object/public/site/services/xbox.png"
              className="hero17-placeholder-image33 thq-img-ratio-1-1 thq-img-scale"
            />
          </div>
        </div>
      </div>
      <div>
        <div className="hero17-container5">
          <Script
            html={`<style>
  @keyframes scroll-x {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - 16px));
    }
  }

  @keyframes scroll-y {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(calc(-100% - 16px));
    }
  }
</style>
`}
          ></Script>
        </div>
      </div>
    </div>
  )
}

Hero17.defaultProps = {
  image4Alt: 'Hero Image',
  image7Alt: 'Hero Image',
  image12Alt: 'Hero Image',
  heading1: undefined,
  image10Alt: 'Hero Image',
  action1: undefined,
  image2Alt: 'Hero Image',
  image8Alt: 'Hero Image',
  image5Alt: 'Hero Image',
  image3Alt: 'Hero Image',
  action2: undefined,
  image11Alt: 'Hero Image',
  image1Alt: 'Hero Image',
  image9Alt: 'Hero Image',
  content1: undefined,
  image6Alt: 'Hero Image',
}

Hero17.propTypes = {
  image4Alt: PropTypes.string,
  image7Alt: PropTypes.string,
  image12Alt: PropTypes.string,
  heading1: PropTypes.element,
  image10Alt: PropTypes.string,
  action1: PropTypes.element,
  image2Alt: PropTypes.string,
  image8Alt: PropTypes.string,
  image5Alt: PropTypes.string,
  image3Alt: PropTypes.string,
  action2: PropTypes.element,
  image11Alt: PropTypes.string,
  image1Alt: PropTypes.string,
  image9Alt: PropTypes.string,
  content1: PropTypes.element,
  image6Alt: PropTypes.string,
}

export default Hero17
