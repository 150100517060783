import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './steps2.css'

const Steps2 = (props) => {
  return (
    <div className="steps2-container1 thq-section-padding">
      <div className="steps2-max-width thq-section-max-width">
        <div className="steps2-container2 thq-grid-2">
          <div className="steps2-section-header">
            <h2 className="steps2-text10 thq-heading-2">
              Как происходит оплата услуги?
            </h2>
          </div>
          <div className="steps2-container3">
            <div className="steps2-container4 thq-card">
              <h2>
                {props.step1Title ?? (
                  <Fragment>
                    <h2 className="steps2-text23 thq-heading-2">Этап 1</h2>
                  </Fragment>
                )}
              </h2>
              <span>
                {props.step1Description ?? (
                  <Fragment>
                    <span className="steps2-text30 thq-body-small">
                      Вы называете менеджеру сервис, который хотите оплатить, и
                      сумму оплаты
                    </span>
                  </Fragment>
                )}
              </span>
              <label className="steps2-text13 thq-heading-3">01</label>
            </div>
            <div className="steps2-container5 thq-card">
              <h2>
                {props.step2Title ?? (
                  <Fragment>
                    <h2 className="steps2-text25 thq-heading-2">Этап 2</h2>
                  </Fragment>
                )}
              </h2>
              <span>
                {props.step2Description ?? (
                  <Fragment>
                    <span className="steps2-text27 thq-body-small">
                      Мы подбираем наилучшие варианты оплаты, а также задаем
                      пару вопросов вам
                    </span>
                  </Fragment>
                )}
              </span>
              <label className="steps2-text16 thq-heading-3">02</label>
            </div>
            <div className="steps2-container6 thq-card">
              <h2>
                {props.step3Title ?? (
                  <Fragment>
                    <h2 className="steps2-text28 thq-heading-2">Этап 3</h2>
                  </Fragment>
                )}
              </h2>
              <span>
                {props.step3Description ?? (
                  <Fragment>
                    <span className="steps2-text29 thq-body-small">
                      Вы оплачиваете наши услуги одним из
                      предоставленных менеджером методов. А мы в свою очередь
                      выдаем данные кредитной карты для оплаты нужного сервиса
                    </span>
                  </Fragment>
                )}
              </span>
              <label className="steps2-text19 thq-heading-3">03</label>
            </div>
            <div className="steps2-container7 thq-card">
              <h2>
                {props.step4Title ?? (
                  <Fragment>
                    <h2 className="steps2-text26 thq-heading-2">Этап 4</h2>
                  </Fragment>
                )}
              </h2>
              <span>
                {props.step4Description ?? (
                  <Fragment>
                    <span className="steps2-text24 thq-body-small">
                      Вы успешно оплачиваете сервис, ваша карта замораживается
                      до следующей оплаты
                    </span>
                  </Fragment>
                )}
              </span>
              <label className="steps2-text22 thq-heading-3">04</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Steps2.defaultProps = {
  step1Title: undefined,
  step4Description: undefined,
  step2Title: undefined,
  step4Title: undefined,
  step2Description: undefined,
  step3Title: undefined,
  step3Description: undefined,
  step1Description: undefined,
}

Steps2.propTypes = {
  step1Title: PropTypes.element,
  step4Description: PropTypes.element,
  step2Title: PropTypes.element,
  step4Title: PropTypes.element,
  step2Description: PropTypes.element,
  step3Title: PropTypes.element,
  step3Description: PropTypes.element,
  step1Description: PropTypes.element,
}

export default Steps2
