import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './contact11.css'

const Contact11 = (props) => {
  return (
    <div className="contact11-container thq-section-padding">
      <div className="contact11-max-width thq-section-max-width">
        <div className="contact11-section-title">
          <div className="contact11-content1">
            <h2>
              {props.heading1 ?? (
                <Fragment>
                  <h2 className="contact11-text5 thq-heading-2">Контакты</h2>
                </Fragment>
              )}
            </h2>
          </div>
        </div>
        <div className="contact11-content2 thq-flex-row">
          <div className="contact11-content3">
            <div className="contact11-content4">
              <svg viewBox="0 0 1024 1024" className="thq-icon-medium">
                <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
              </svg>
              <div className="contact11-contact-info1">
                <h3 className="contact11-text2 thq-heading-3">Почта</h3>
                <a
                  href="mailto:sale@paylo.one?subject=У меня вопрос"
                  className="contact11-email1"
                >
                  {props.email ?? (
                    <Fragment>
                      <span className="contact11-text6 thq-body-small">
                        sale@paylo.one
                      </span>
                    </Fragment>
                  )}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="contact11-content5 thq-flex-row">
          <div className="contact11-content6">
            <div className="contact11-content7">
              <svg viewBox="0 0 1024 1024" className="contact11-icon3">
                <path d="M726 470v-86h-86v86h86zM554 470v-86h-84v86h84zM384 470v-86h-86v86h86zM854 86q34 0 59 25t25 59v512q0 34-25 60t-59 26h-598l-170 170v-768q0-34 25-59t59-25h684z"></path>
              </svg>
              <div className="contact11-contact-info2">
                <h3 className="contact11-text3 thq-heading-3">Наш телеграм</h3>
                <a
                  href="https://t.me/paylo_global"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="contact11-email2"
                >
                  {props.email1 ?? (
                    <Fragment>
                      <span className="contact11-text4 thq-body-small">
                        https://t.me/paylo_global
                      </span>
                    </Fragment>
                  )}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Contact11.defaultProps = {
  email1: undefined,
  heading1: undefined,
  email: undefined,
}

Contact11.propTypes = {
  email1: PropTypes.element,
  heading1: PropTypes.element,
  email: PropTypes.element,
}

export default Contact11
