import React, { Fragment } from 'react'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import Navbar8 from '../components/navbar8'
import Hero17 from '../components/hero17'
import Features24 from '../components/features24'
import Steps2 from '../components/steps2'
import Footer4 from '../components/footer4'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container1">
      <Helmet>
        <title>PAYlo</title>
        <meta property="og:title" content="PAYlo" />
      </Helmet>
      <Navbar8
        link1={
          <Fragment>
            <span className="home-text10 thq-body-small thq-link">Тарифы</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="home-text11 thq-body-small thq-link">
              Контакты
            </span>
          </Fragment>
        }
        page1={
          <Fragment>
            <span className="home-text12 thq-body-large">Page One</span>
          </Fragment>
        }
        page2={
          <Fragment>
            <span className="home-text13 thq-body-large">Page Two</span>
          </Fragment>
        }
        page3={
          <Fragment>
            <span className="home-text14 thq-body-large">Page Three</span>
          </Fragment>
        }
        page4={
          <Fragment>
            <span className="home-text15 thq-body-large">Page Four</span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="home-text16">Начать оплату</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="home-text17">Помощь</span>
          </Fragment>
        }
        rootClassName="navbar8-root-class-name6"
        page1Description={
          <Fragment>
            <span className="home-text18 thq-body-small">
              Page One Description
            </span>
          </Fragment>
        }
        page2Description={
          <Fragment>
            <span className="home-text19 thq-body-small">
              Page Two Description
            </span>
          </Fragment>
        }
        page3Description={
          <Fragment>
            <span className="home-text20 thq-body-small">
              Page Three Description
            </span>
          </Fragment>
        }
        page4Description={
          <Fragment>
            <span className="home-text21 thq-body-small">
              Page Four Description
            </span>
          </Fragment>
        }
      ></Navbar8>
      <Hero17
        action1={
          <Fragment>
            <span className="home-text22 thq-body-small">
              Оплатить подписку
            </span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="home-text23 thq-body-small">Задать вопрос</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <p className="home-text24 thq-body-large">
              Комиссия от 6%. Оплата криптовалютой, СБП, P2P
            </p>
          </Fragment>
        }
        heading1={
          <Fragment>
            <h1 className="home-text25 thq-heading-1">
              Поможем оплатить зарубежные сервисы
            </h1>
          </Fragment>
        }
      ></Hero17>
      <Features24
        feature1Title={
          <Fragment>
            <h2 className="home-text26 thq-heading-2">Плюс #1</h2>
          </Fragment>
        }
        feature2Title={
          <Fragment>
            <h2 className="home-text27 thq-heading-2">Плюс #2</h2>
          </Fragment>
        }
        feature3Title={
          <Fragment>
            <h2 className="home-text28 thq-heading-2">Плюс #3</h2>
          </Fragment>
        }
        feature1Description={
          <Fragment>
            <span className="home-text29 thq-body-small">
              Для каждого пользователя создается карта, что является однозначным
              плюсом. Карты будут подвязываться к сервисам без каких-либо
              проблем.
            </span>
          </Fragment>
        }
        feature2Description={
          <Fragment>
            <span className="home-text30 thq-body-small">
              Бины карт не только дебетовые, но и кредитные - это также
              способствует проходимости в сервисах.
            </span>
          </Fragment>
        }
        feature3Description={
          <Fragment>
            <span className="home-text31 thq-body-small">
              Если вы захотите вернуть средства с карты, то это не проблема. Мы
              можем выплатить остаток средств с карты вам с помощью СБП или
              криптовалюты
            </span>
          </Fragment>
        }
      ></Features24>
      <Steps2
        step1Title={
          <Fragment>
            <h2 className="home-text32 thq-heading-2">Этап 1</h2>
          </Fragment>
        }
        step2Title={
          <Fragment>
            <h2 className="home-text33 thq-heading-2">Этап 2</h2>
          </Fragment>
        }
        step3Title={
          <Fragment>
            <h2 className="home-text34 thq-heading-2">Этап 3</h2>
          </Fragment>
        }
        step4Title={
          <Fragment>
            <h2 className="home-text35 thq-heading-2">Этап 4</h2>
          </Fragment>
        }
        step1Description={
          <Fragment>
            <span className="home-text36 thq-body-small">
              Вы называете менеджеру сервис, который хотите оплатить, и сумму
              оплаты
            </span>
          </Fragment>
        }
        step2Description={
          <Fragment>
            <span className="home-text37 thq-body-small">
              Мы подбираем наилучшие варианты оплаты, а также задаем пару
              вопросов вам
            </span>
          </Fragment>
        }
        step3Description={
          <Fragment>
            <span className="home-text38 thq-body-small">
              Вы оплачиваете наши услуги одним из предоставленных менеджером
              методов. А мы в свою очередь выдаем данные кредитной карты для
              оплаты нужного сервиса
            </span>
          </Fragment>
        }
        step4Description={
          <Fragment>
            <span className="home-text39 thq-body-small">
              Вы успешно оплачиваете сервис, ваша карта замораживается до
              следующей оплаты
            </span>
          </Fragment>
        }
      ></Steps2>
      <div className="home-container2">
        <h2 className="home-text40">Калькулятор</h2>
        <div className="home-container3">
          <div className="home-container4">
            <div className="home-container5">
              <Script
                html={`<div id="calculator">
    <input type="number" id="amount" placeholder="Введите сумму в USD" min="25" step="0.01">
    <div id="buttons">
        <button id="crypto" class="payment-button">Оплата криптовалютой</button>
        <button id="fiat" class="payment-button">Оплата фиатом</button>
    </div>
    <label class="toggle">
        <input type="checkbox" id="returning-customer">
        <span class="slider"></span>
        <span class="label-text">Я ранее использовал услуги PAYlo</span>
    </label>
    <div id="total">Итого: <span class="total-amount">\$0.00</span></div>
    <div id="explanation"></div>
</div>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Martian+Mono:wght@400;700&display=swap');

    #calculator {
        font-family: 'Martian Mono', monospace;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        padding: 20px;
        max-width: 400px;
        margin: 20px auto;
    }
    #amount {
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        font-family: 'Martian Mono', monospace;
    }
    #buttons {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .payment-button {
        padding: 10px 15px;
        border: 2px solid #000000;
        border-radius: 5px;
        cursor: pointer;
        background-color: #000000;
        color: #ffffff;
        transition: all 0.3s;
        flex-grow: 1;
        margin: 0 5px;
        font-family: 'Martian Mono', monospace;
        font-size: 14px;
    }
    .payment-button:first-child {
        margin-left: 0;
    }
    .payment-button:last-child {
        margin-right: 0;
    }
    .payment-button:hover {
        opacity: 0.8;
    }
    .payment-button.active {
        background-color: #ffffff;
        color: #000000;
    }
    .toggle {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
    .toggle input {
        display: none;
    }
    .slider {
        width: 40px;
        height: 20px;
        background-color: #ccc;
        border-radius: 20px;
        position: relative;
        transition: 0.3s;
        margin-right: 10px;
    }
    .slider:before {
        content: "";
        position: absolute;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: white;
        top: 1px;
        left: 1px;
        transition: 0.3s;
    }
    input:checked + .slider {
        background-color: black;
    }
    input:checked + .slider:before {
        transform: translateX(20px);
    }
    #total {
        margin-top: 20px;
        font-size: 18px;
    }
    .total-amount {
        font-weight: 700;
    }
    #explanation {
        margin-top: 10px;
        color: #777;
        font-size: 14px;
    }
</style>

<script>
    const amountInput = document.getElementById('amount');
    const cryptoButton = document.getElementById('crypto');
    const fiatButton = document.getElementById('fiat');
    const returningCustomerToggle = document.getElementById('returning-customer');
    const totalDiv = document.getElementById('total');
    const explanationDiv = document.getElementById('explanation');

    let exchangeRate = 0;
    let selectedPaymentMethod = null;

    function getExchangeRate() {
        fetch('https://api.exchangerate-api.com/v4/latest/USD')
            .then(response => response.json())
            .then(data => {
                exchangeRate = data.rates.RUB * 1.05; // Добавляем 5% наценку
                if (selectedPaymentMethod) {
                    calculate(selectedPaymentMethod);
                }
            })
            .catch(error => {
                console.error('Ошибка при получении курса:', error);
                exchangeRate = 100; // Значение по умолчанию, если API недоступен
                if (selectedPaymentMethod) {
                    calculate(selectedPaymentMethod);
                }
            });
    }

    getExchangeRate();
    setInterval(getExchangeRate, 60000);

    function calculate(paymentMethod) {
        let amount = parseFloat(amountInput.value);
        if (isNaN(amount) || amount < 25) {
            amount = 25;
            amountInput.value = 25;
        }

        let total = amount;
        let explanation = [];

        if (!returningCustomerToggle.checked) {
            total += 3;
            explanation.push("Комиссия за выпуск карты: \$3");
        }

        if (paymentMethod === 'crypto') {
            let commission = total * 0.06;
            total += commission;
            explanation.push(\`Комиссия за криптоплатеж (6%): \$\${commission.toFixed(2)}\`);
        } else if (paymentMethod === 'fiat') {
            total *= exchangeRate;
            let commission = total * 0.14;
            total += commission;
            explanation.push(\`Конвертация в рубли по курсу: ₽\${exchangeRate.toFixed(2)}\`);
            explanation.push(\`Комиссия за фиатный платеж (14%): ₽\${commission.toFixed(2)}\`);
        }

        totalDiv.innerHTML = paymentMethod === 'fiat' 
            ? \`Итого: <span class="total-amount">₽\${total.toFixed(2)}</span>\` 
            : \`Итого: <span class="total-amount">\$\${total.toFixed(2)}</span>\`;
        explanationDiv.innerHTML = explanation.join('<br>');
    }

    function setActiveButton(button) {
        document.querySelectorAll('.payment-button').forEach(btn => btn.classList.remove('active'));
        button.classList.add('active');
    }

    cryptoButton.addEventListener('click', () => {
        selectedPaymentMethod = 'crypto';
        setActiveButton(cryptoButton);
        calculate('crypto');
    });

    fiatButton.addEventListener('click', () => {
        selectedPaymentMethod = 'fiat';
        setActiveButton(fiatButton);
        calculate('fiat');
    });

    amountInput.addEventListener('input', () => {
        if (selectedPaymentMethod) {
            calculate(selectedPaymentMethod);
        }
    });

    returningCustomerToggle.addEventListener('change', () => {
        if (selectedPaymentMethod) {
            calculate(selectedPaymentMethod);
        }
    });
</script>`}
              ></Script>
            </div>
          </div>
        </div>
      </div>
      <Footer4
        link2={
          <Fragment>
            <span className="home-text41 thq-body-small">Мы в ТГ</span>
          </Fragment>
        }
        termsLink={
          <Fragment>
            <span className="home-text42 thq-body-small">Оферта</span>
          </Fragment>
        }
      ></Footer4>
    </div>
  )
}

export default Home
