import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './content-list1.css'

const ContentList1 = (props) => {
  return (
    <div className="content-list1-container thq-section-padding">
      <div className="content-list1-max-width thq-section-max-width">
        <div className="content-list1-content thq-flex-column">
          <ul className="content-list1-ul thq-flex-column">
            <li className="list-item thq-flex-column">
              <h2>
                {props.heading1 ?? (
                  <Fragment>
                    <h2 className="content-list1-text89 thq-heading-2">
                      Правила использования
                    </h2>
                  </Fragment>
                )}
              </h2>
              <p>
                {props.content1 ?? (
                  <Fragment>
                    <p className="content-list1-text11 thq-body-small">
                      <span>
                        Настоящий договор (Публичная оферта) определяет
                        дальнейшие взаимоотношения между исполнителем - сайт
                        paylo.one (далее &quot;Мы&quot;, &quot;Наша&quot;,
                        &quot;paylo&quot;, &quot;paylo.one&quot;,
                        &quot;https://paylo.one/&quot;, &quot;Посредник&quot;,
                        &quot;Оператор&quot;, &quot;Исполнитель&quot;) с одной
                        стороны и физическим либо юридическим лицом группы
                        &quot;Пользователь&quot; (далее &quot;Вы&quot;,
                        &quot;Заказчик&quot;, &quot;Клиент&quot;) с другой.
                      </span>
                      <br></br>
                      <br></br>
                      <span>
                        <span>
                          Сервис paylo.one занимается помощью с восстановлием
                          доступа к сервисам, которые не запрещены на территории
                          РФ.
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                      </span>
                    </p>
                  </Fragment>
                )}
              </p>
            </li>
            <li className="list-item">
              <h3>
                {props.heading2 ?? (
                  <Fragment>
                    <h3 className="content-list1-text99 thq-heading-3">
                      1.0 Общие положения и порядок оплаты
                    </h3>
                  </Fragment>
                )}
              </h3>
              <p>
                {props.content2 ?? (
                  <Fragment>
                    <p className="content-list1-text18 thq-body-small">
                      <span>
                        1.1 Факт отправки заявки на сайте https://paylo.one и
                        (или) оформления Клиентом заказа через мессенджеры, либо
                        через email является подтверждением полного и
                        безоговорочным принятия условий данного соглашения.
                      </span>
                      <br></br>
                      <span>
                        1.2 Соглашаясь на сделку с нами, Заказчик дает согласие
                        на обработку своих персональных данных согласно нашей
                        Политике Конфиденциальности
                      </span>
                      <br></br>
                      <span>
                        1.2 В случае нарушения Клиентом условий данного
                        Соглашения, уличении его в мошеннических и (или)
                        противозаконных действиях, Посредник оставляет за собой
                        право на разрыв данного договора в одностороннем порядке
                        и использование иных соответственных мер по пресечению
                        последствий этих нарушений в случае их возникновения.
                      </span>
                      <br></br>
                      <span>
                        1.3 Заказчик обязуется проверить сайт/сервис/услугу,
                        который ему необходимо оплатить. Убедиться, что сайт не
                        нарушает закон РФ.
                      </span>
                      <br></br>
                      <span>
                        1.4 Добавьте новую заявку на наш сервис paylo.one на
                        оплату
                      </span>
                      <br></br>
                      <span>1.5 Мы проверяем возможность её исполнения.</span>
                      <br></br>
                      <span>
                        1.6 В случае возможности её оплаты, мы уведомляем Вас об
                        этом и направляем реквизиты для оплаты.
                      </span>
                      <br></br>
                      <span>
                        1.7 После оплаты на наши реквизиты, Вы должны уведомить
                        нас об этом и приложить чек (скриншот).
                      </span>
                      <br></br>
                      <span>
                        1.8 Мы оплачиваем необходимый сервис/товар/услугу и
                        присылаем вам отчёт.
                      </span>
                      <br></br>
                      <span>
                        <span>
                          1.9 Если стоимость сервиса/товара/услуги превысила
                          сумму, которую Вы отправили, то Вы должны
                          дополнительно прислать остаток суммы без учёта нашей
                          комиссии.
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                      </span>
                    </p>
                  </Fragment>
                )}
              </p>
            </li>
            <li className="content-list1-li12 list-item">
              <h3>
                {props.heading3 ?? (
                  <Fragment>
                    <h3 className="content-list1-text98 thq-heading-3">
                      2.0 Заказчик обязан
                    </h3>
                  </Fragment>
                )}
              </h3>
              <p>
                {props.content3 ?? (
                  <Fragment>
                    <p className="content-list1-text62 thq-body-small">
                      <span>
                        2.1 Заказчик обязан самостоятельно проверить
                        сайт/сервис/услугу, которую он хочет оплатить.
                      </span>
                      <br></br>
                      <span>
                        2.2 Мы работаем по полной предоплате, постоплата
                        исключена. Запрещено отправлять средства по нашим
                        реквизитам без предварительного подтверждения
                        возможности её исполнения.
                      </span>
                      <br></br>
                      <span>
                        2.3 Убедиться, что сервис/товар/услугу не нарушает
                        законы Российской Федерации.
                      </span>
                      <br></br>
                      <span>
                        2.4 Предоставить данные для входа в аккаунт, с которого
                        необходимо произвести оплату.
                      </span>
                      <br></br>
                      <span>
                        <span>
                          2.4 Убедиться, что товар может быть доставлен в
                          Россию, на котором необходимо провести оплату. Мы
                          занимаемся только оплатой желаемого
                          сервиса/товара/услуги.
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                      </span>
                    </p>
                  </Fragment>
                )}
              </p>
            </li>
            <li className="content-list1-li13 list-item">
              <h3>
                {props.heading4 ?? (
                  <Fragment>
                    <h3 className="content-list1-text74 thq-heading-3">
                      3.0 Сроки исполнения услуги
                    </h3>
                  </Fragment>
                )}
              </h3>
              <p>
                {props.content4 ?? (
                  <Fragment>
                    <p className="content-list1-text58 thq-body-small">
                      <span>
                        3.1 После предоставления всех данных и оплаты по нашим
                        реквизитам мы обязаны оплатить сервис/товар/услугу в
                        течение 2 (двух) дней. Сроки могут быть увеличены по
                        согласованию Испольнителя и Заказчика.
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                    </p>
                  </Fragment>
                )}
              </p>
            </li>
            <li className="content-list1-li14 list-item">
              <h3>
                {props.heading5 ?? (
                  <Fragment>
                    <h3 className="content-list1-text42 thq-heading-3">
                      4.0 Отказ от услуги
                    </h3>
                  </Fragment>
                )}
              </h3>
              <p>
                {props.content5 ?? (
                  <Fragment>
                    <p className="content-list1-text85 thq-body-small">
                      <span>
                        4.1 Мы можем отказаться от исполнения нашей услуги
                        (оплаты/пополнения и т.п. сервиса/товара/услуги и т.п. )
                        в любой этап сделки и без объяснительных причин.
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                    </p>
                  </Fragment>
                )}
              </p>
            </li>
            <li className="content-list1-li15 list-item">
              <h3>
                {props.heading6 ?? (
                  <Fragment>
                    <h3 className="content-list1-text88 thq-heading-3">
                      5.0 Возврат
                    </h3>
                  </Fragment>
                )}
              </h3>
              <p>
                {props.content6 ?? (
                  <Fragment>
                    <p className="content-list1-text47 thq-body-small">
                      <span>
                        5.1 В случае успешной оплаты мы не возвращаем средства,
                        которые уже были отправлены для оплаты
                        сервиса/товара/услуги.
                      </span>
                      <br></br>
                      <span>
                        5.2 Если мы не оплатим сервис/товар/услугу в течение 2
                        (двух) дней или в другой срок, обговоренный с
                        Заказчиком, то мы обязаны вернуть Ваши средства обратно
                        Вам на счёт. Возврат осуществляется только на ту же
                        платёжную систему (карту), с которой Вы произвели
                        оплату.
                      </span>
                      <br></br>
                      <span>
                        5.3 Клиент (заказчик) обязан предоставить номер
                        банковской карты или кошелька для возврата средств в
                        течение 30 дней после получения письменного запроса
                        (просьба, сообщение в социальной сети, комментарий).
                        Необходимо предоставить номер банковской карты или
                        кошелька той компании, на которую и был совершен платёж.
                        Если по истечению 30 дней клиент не предоставить ответ
                        на запрос, то мы оставляем за собой право не возвращать
                        средства.
                      </span>
                      <br></br>
                      <span>
                        <span>
                          5.4 В случае, если у магазина нет части товаров,
                          которые уже оплачены, то мы не возвращаем средства по
                          товарам, которых нет. Перед оформлением заявки Вы
                          обязаны удостовериться, что весь необходимый товар
                          есть в наличии.
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                      </span>
                    </p>
                  </Fragment>
                )}
              </p>
            </li>
            <li className="content-list1-li16 list-item">
              <h3>
                {props.heading61 ?? (
                  <Fragment>
                    <h3 className="content-list1-text80 thq-heading-3">
                      6.0 Если сервис отказал в оплате
                    </h3>
                  </Fragment>
                )}
              </h3>
              <p>
                {props.content61 ?? (
                  <Fragment>
                    <p className="content-list1-text82 thq-body-small">
                      <span>
                        6.1 Если зарубежный сервис/интернет-магазин/компания
                        откажет в оплате не по нашей вине (оплата не будет
                        произведена), то мы вернём вам всю сумму без учёта нашей
                        уже взятой комиссии за работу. Возврат осуществляется в
                        течение 90 дней (обычно в течение нескольких часов).
                        Возврат осуществляется только на ту платёжную систему
                        (карту), с которой вы произвели оплату. В случаем
                        успешной оплаты средства возвращаются по правилу 5.1.
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                    </p>
                  </Fragment>
                )}
              </p>
            </li>
            <li className="content-list1-li17 list-item">
              <h3>
                {props.heading611 ?? (
                  <Fragment>
                    <h3 className="content-list1-text61 thq-heading-3">
                      7.0 Если сервис/сайт заблокировал Ваш аккаунт
                    </h3>
                  </Fragment>
                )}
              </h3>
              <p>
                {props.content611 ?? (
                  <Fragment>
                    <p className="content-list1-text75 thq-body-small">
                      <span>
                        7.1 В случае успешной оплаты мы не возвращаем средства,
                        которые уже были отправлены для оплаты
                        сервиса/товара/услуги.
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                    </p>
                  </Fragment>
                )}
              </p>
            </li>
            <li className="content-list1-li18 list-item">
              <h3>
                {props.heading6111 ?? (
                  <Fragment>
                    <h3 className="content-list1-text10 thq-heading-3">
                      8.0 Другие обстоятельства возврата средств
                    </h3>
                  </Fragment>
                )}
              </h3>
              <p>
                {props.content6111 ?? (
                  <Fragment>
                    <p className="content-list1-text44 thq-body-small">
                      <span>
                        8.1 В случае успешной оплаты мы не возвращаем средства,
                        которые уже были отправлены для оплаты
                        сервиса/товара/услуги.
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                    </p>
                  </Fragment>
                )}
              </p>
            </li>
            <li className="content-list1-li19 list-item">
              <h3>
                {props.heading61112 ?? (
                  <Fragment>
                    <h3 className="content-list1-text40 thq-heading-3">
                      9.0 Комиссия сервиса
                    </h3>
                  </Fragment>
                )}
              </h3>
              <p>
                {props.content61112 ?? (
                  <Fragment>
                    <p className="content-list1-text79 thq-body-small">
                      9.1 Актуальная и полная комиссия устанавливается в
                      телеграм-канале сервиса paylo. Комиссия зависит от суммы
                      платежа и метода платежа.
                    </p>
                  </Fragment>
                )}
              </p>
            </li>
            <li className="content-list1-li20 list-item">
              <h3>
                {props.heading61111 ?? (
                  <Fragment>
                    <h3 className="content-list1-text78 thq-heading-3">
                      10.0 Форс-мажорные обстоятельства
                    </h3>
                  </Fragment>
                )}
              </h3>
              <p>
                {props.content61111 ?? (
                  <Fragment>
                    <p className="content-list1-text90 thq-body-small">
                      <span>
                        10.1. Ни одна из Сторон не несет ответственности перед
                        другой Стороной за невыполнение обязательств, вызванное
                        обстоятельствами, возникшими помимо воли и желания
                        Сторон, которые нельзя было предвидеть или избежать,
                        включая объявленную или фактическую войну, гражданские
                        волнения, эпидемии, землетрясения, наводнения, пожары и
                        другие стихийные бедствия, действия государственных или
                        муниципальных органов и прочие непреодолимые
                        обстоятельства.
                      </span>
                      <br></br>
                      <span>
                        10.2. Сторона, которая не исполняет своего обязательства
                        вследствие действия непреодолимой силы, должна известить
                        другую Сторону о препятствии и его влиянии на исполнение
                        обязательств без промедления, но не позднее 30
                        (тридцати) дней с момента наступления указанных
                        обстоятельств.
                      </span>
                      <br></br>
                      <span>
                        <span>
                          10.3. Сторона, не известившая другую Сторону о
                          невозможности исполнения своих обязательств по
                          настоящему Договору, теряет право ссылаться на такую
                          невозможность
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                      </span>
                    </p>
                  </Fragment>
                )}
              </p>
            </li>
            <li className="content-list1-li21 list-item">
              <h3>
                {props.heading611112 ?? (
                  <Fragment>
                    <h3 className="content-list1-text43 thq-heading-3">
                      11.0 Публичная оферта
                    </h3>
                  </Fragment>
                )}
              </h3>
              <p>
                {props.content611112 ?? (
                  <Fragment>
                    <p className="content-list1-text81 thq-body-small">
                      11.1 Данные правила доступны в публичной оферте.
                      Актуальное правила находится по адресу:
                      https://paylo.one/legal/
                    </p>
                  </Fragment>
                )}
              </p>
            </li>
            <li className="content-list1-li22 list-item">
              <h3>
                {props.heading611111 ?? (
                  <Fragment>
                    <h3 className="content-list1-text57 thq-heading-3">
                      12.0 Изменение данных правил
                    </h3>
                  </Fragment>
                )}
              </h3>
              <p>
                {props.content611111 ?? (
                  <Fragment>
                    <p className="content-list1-text41 thq-body-small">
                      12.1 Правила сервисов и оплаты постоянно меняются, поэтому
                      мы вправе изменять данные правила без дополнительного
                      уведомления. Актуальные правила пользования сервисом
                      всегда доступны по адресу https://paylo.one/legal/
                    </p>
                  </Fragment>
                )}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

ContentList1.defaultProps = {
  heading6111: undefined,
  content1: undefined,
  content2: undefined,
  heading61112: undefined,
  content611111: undefined,
  heading5: undefined,
  heading611112: undefined,
  content6111: undefined,
  content6: undefined,
  heading611111: undefined,
  content4: undefined,
  heading611: undefined,
  content3: undefined,
  heading4: undefined,
  content611: undefined,
  heading61111: undefined,
  content61112: undefined,
  heading61: undefined,
  content611112: undefined,
  content61: undefined,
  content5: undefined,
  heading6: undefined,
  heading1: undefined,
  content61111: undefined,
  heading3: undefined,
  heading2: undefined,
}

ContentList1.propTypes = {
  heading6111: PropTypes.element,
  content1: PropTypes.element,
  content2: PropTypes.element,
  heading61112: PropTypes.element,
  content611111: PropTypes.element,
  heading5: PropTypes.element,
  heading611112: PropTypes.element,
  content6111: PropTypes.element,
  content6: PropTypes.element,
  heading611111: PropTypes.element,
  content4: PropTypes.element,
  heading611: PropTypes.element,
  content3: PropTypes.element,
  heading4: PropTypes.element,
  content611: PropTypes.element,
  heading61111: PropTypes.element,
  content61112: PropTypes.element,
  heading61: PropTypes.element,
  content611112: PropTypes.element,
  content61: PropTypes.element,
  content5: PropTypes.element,
  heading6: PropTypes.element,
  heading1: PropTypes.element,
  content61111: PropTypes.element,
  heading3: PropTypes.element,
  heading2: PropTypes.element,
}

export default ContentList1
